.download-style{
    text-decoration: none;
    border-radius: 5px;
    width: 250px;
    height: 50px;
    color: blanchedalmond;
    background-color:black;
}
.download-style a{
    text-decoration: none;
}
